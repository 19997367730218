export default {
  appName     : 'ChatHub',
  globalError : '發生錯誤，請聯絡技術人員！',
  navbar      : {
    try      : '立即體驗',
    language : {
      tw : '繁體中文',
      en : 'English'
    },
    target : {
      home      : '首頁',
      features  : '產品特點',
      scenarios : '使用情境',
      contact   : '聯絡我們'
    }
  },
  dialog : {
    try        : '立即體驗',
    username   : '使用者名稱',
    userLogin  : '會員模式登入',
    guestLogin : '訪客模式'
  },
  home : {
    title_1 : 'ChatHub：',
    title_2 : '企業專屬通訊平台',
    desc_1  : 'ChatHub是一款專為企業設計的通訊平台。',
    desc_2  : '它提供私密、嚴謹的權限管理和高度使用彈性。',
    desc_3  : '無論是內部溝通還是客戶服務，ChatHub都能滿足多樣化的需求。',
    try     : '立即體驗'
  },
  features : {
    customization                 : '高彈性自定義',
    customization_desc_1          : '支援自定義聊天室',
    customization_desc_2          : '限制入群、發言……等功能',
    customization_desc_3          : '滿足多種應用場景',
    privacy_security              : '私密與安全性',
    privacy_security_desc_1       : '資料由客戶自行控管',
    privacy_security_desc_2       : '詳實記錄聊天紀錄，可隨時追溯',
    streamlining_processes        : '縮減既有流程',
    streamlining_processes_desc_1 : '提供 API 串接',
    streamlining_processes_desc_2 : '輕鬆整合原有系統，縮減作業流程',
    ai_collaboration              : '人工智慧協力',
    ai_collaboration_desc_1       : '可訂製 AI 工具',
    ai_collaboration_desc_2       : '彙整聊天內容摘要、話題搜尋',
    ai_collaboration_desc_3       : '完善企業知識庫，導正過時資訊'
  },
  security : {
    title     : '資安控管好輕鬆',
    desc_1    : '最頭痛的人事流動、權限、資料安全性問題，',
    desc_2    : '在ChatHub 一次搞定！',
    step_1    : '透過後台建立群組',
    step_2    : '新員工申辦企業系統帳號',
    step_3_1  : '根據員工身分組',
    step_3_2  : '自動加入聊天室',
    feature_1 : '後台可查詢所有使用者資料及聊天紀錄',
    feature_2 : '人事異動時，快速退群／入群',
    feature_3 : '控管所有檔案上傳、瀏覽、下載權限',
    feature_4 : '杜絕外部人士入群的可能'
  },
  custom_service : {
    title  : '客服交接免煩惱',
    desc_1 : '客服帳號 N in one。',
    desc_2 : '多個客服帳號可使用統一的對外身份，',
    desc_3 : '提升客戶體驗'
  },
  ai : {
    title  : '資訊討論不過時',
    desc   : '利用 AI 完善企業知識庫，即時核對聊天內容，確保沒有過時資訊',
    button : '立即聯繫業務團隊，打造企業專屬 AI 幫手'
  },
  contact : {
    subject       : 'ChatHub聯絡我們信件',
    submitSuccess : '成功送出！',
    continue      : '繼續填寫新訊息？'
  },
  redirect : {
    errorMessageTitle : '發生錯誤！'
  },
  formTemplate : {
    upload       : '上傳檔案',
    reset        : '重填',
    submit       : '確定送出',
    input        : '輸入',
    choose       : '選擇',
    beforeRemove : '確定移除',
    handleExceed : '最多上傳一張,請先刪除上一選項',
    error        : '失敗',
    cancel       : '取消'
  },
  form : {
    required            : '「{field}」為必填。',
    emailRequireMessage : '請輸入email',
    emailFormatMessage  : 'Email 格式錯誤',
    emailPlaceholder    : '請輸入email',
    imageSizeLimitMb    : '圖片不得超過{limit}mb。',
    buttonConfirm       : '確定',
    search              : '搜尋',
    clear               : '清除',
    back                : '返回'
  },
  errorPage : {
    403 : {
      p1 : '您沒有權限訪問該頁面',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    },
    404 : {
      p1 : '您訪問的頁面不存在！',
      p2 : '返回首頁',
      p3 : '返回上一頁'
    }
  }
}
