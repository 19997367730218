import { createRouter, createWebHistory } from 'vue-router'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import { i18nInstance } from '@/plugins/i18n.js'
import basicRoute from '@/router/basic.js'

let i18n = i18nInstance()
// NProgress.configure({ showSpinner : false })

const errorRoute = {
  path     : '/:pathMatch(.*)*', // 捕獲所有未匹配的路由
  redirect : '/404'
}

const routes = [...basicRoute, errorRoute]
const router = createRouter({
  history : createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // NProgress.start()
  window.document.title = (to.meta.title ? to.meta.title + ' - ' : '') + i18n.global.t('appName')
  next()
})

// router.afterEach(() => {
//   NProgress.done()
// })

export default router
