// import { routeMiddleware } from '@/utils/middleware'
import { i18nInstance } from '@/plugins/i18n.js'

let i18n = i18nInstance()

export default [
  {
    path      : '/',
    name      : 'LandingPage',
    component : () => import('@/views/LandingPage/index.vue')
  },
  {
    path      : '/404',
    name      : '404',
    component : () => import('@/views/ErrorPage/404.vue')
  }
]
