export default {
  appName     : 'ChatHub',
  globalError : 'An error occurred. Please contact technical support!',
  navbar      : {
    try      : 'Try Now',
    language : {
      tw : '繁體中文',
      en : 'English'
    },
    target : {
      home      : 'Home',
      features  : 'Features',
      scenarios : 'Scenarios',
      contact   : 'Contact Us'
    }
  },
  dialog : {
    try        : 'Try Now',
    username   : 'Username',
    userLogin  : 'User Login',
    guestLogin : 'Guest Mode'
  },
  home : {
    title_1 : 'ChatHub:',
    title_2 : 'Enterprise Communication Platform',
    desc_1  : 'ChatHub is a communication platform designed specifically for enterprises.',
    desc_2  : 'It provides private, rigorous permission management and high flexibility.',
    desc_3  : 'Whether for internal communication or customer service, ChatHub meets a variety of needs.',
    try     : 'Try Now'
  },
  features : {
    customization                 : 'High Flexibility Customization',
    customization_desc_1          : 'Supports custom chat rooms',
    customization_desc_2          : 'Restrict group entry, speaking, and other functions',
    customization_desc_3          : 'Meets various application scenarios',
    privacy_security              : 'Privacy and Security',
    privacy_security_desc_1       : 'Data controlled by the customer',
    privacy_security_desc_2       : 'Detailed chat records, traceable at any time',
    streamlining_processes        : 'Streamline Existing Processes',
    streamlining_processes_desc_1 : 'Provides API integration',
    streamlining_processes_desc_2 : 'Easily integrates with existing systems, reducing operational processes',
    ai_collaboration              : 'AI Collaboration',
    ai_collaboration_desc_1       : 'Customizable AI tools',
    ai_collaboration_desc_2       : 'Summarizes chat content, topic search',
    ai_collaboration_desc_3       : 'Improves enterprise knowledge base, updates outdated information'
  },
  security : {
    title     : 'Easy Security Management',
    desc_1    : 'Handle personnel changes, permissions, and data security issues effortlessly with ChatHub!',
    desc_2    : 'All managed in one place!',
    step_1    : 'Create groups through the backend',
    step_2    : 'New employees apply for corporate system accounts',
    step_3_1  : 'Organize based on employee roles',
    step_3_2  : 'Automatically join chat rooms',
    feature_1 : 'View all user data and chat records in the backend',
    feature_2 : 'Quickly add or remove users during personnel changes',
    feature_3 : 'Control file upload, viewing, and download permissions',
    feature_4 : 'Prevent unauthorized access'
  },
  custom_service : {
    title  : 'No Worries About Customer Service Handover',
    desc_1 : 'N-in-one customer service accounts.',
    desc_2 : 'Multiple customer service accounts can use a unified external identity,',
    desc_3 : 'Enhancing customer experience'
  },
  ai : {
    title  : 'Up-to-Date Information Discussion',
    desc   : 'Utilize AI to improve the enterprise knowledge base, verify chat content in real-time, and ensure no outdated information',
    button : 'create a customized AI assistant for your enterprise'
  },
  contact : {
    subject       : 'Contact Us Email from ChatHub',
    submitSuccess : 'sent successfully !',
    continue      : 'continue to fill in new message?'
  },
  redirect : {
    errorMessageTitle : 'An Error Occurred!'
  },
  formTemplate : {
    upload       : 'Upload File',
    reset        : 'Reset',
    submit       : 'Submit',
    input        : 'Input',
    choose       : 'Choose',
    beforeRemove : 'Confirm Removal',
    handleExceed : 'Upload up to one file. Please delete the previous item first',
    error        : 'Failed',
    cancel       : 'Cancel'
  },
  form : {
    required            : '「{field}」 is required.',
    emailRequireMessage : 'Please enter an email',
    emailFormatMessage  : 'Invalid email format',
    emailPlaceholder    : 'Please enter your email',
    imageSizeLimitMb    : 'Image must not exceed {limit} MB.',
    buttonConfirm       : 'Confirm',
    search              : 'Search',
    clear               : 'Clear',
    back                : 'Back'
  },
  errorPage : {
    403 : {
      p1 : 'You do not have permission to access this page',
      p2 : 'Return to homepage',
      p3 : 'Return to previous page'
    },
    404 : {
      p1 : 'The page you are looking for does not exist!',
      p2 : 'Return to homepage',
      p3 : 'Return to previous page'
    }
  }
}
